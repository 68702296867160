/* eslint-disable import/prefer-default-export */
export const optionsSeller = (t) => [
    {
        aclCode: 'seller_catalog_master',
        key: 'catalogproduct',
        label: t('menu:Catalog'),
        notInAcl: true,
        isMultichannel: true,
        children: [
            {
                aclCode: 'seller_master_product',
                key: 'masterproduct',
                label: t('menu:Master_Product'),
                url: '/seller/catalogproduct/masterproduct',
                notInAcl: true,
            },
            {
                aclCode: 'seller_channel_product',
                key: 'channelproduct',
                label: t('menu:Channel_Product'),
                notInAcl: true,
                children: [
                ],
            },
            {
                aclCode: 'seller_master_product',
                key: 'masschange',
                label: t('menu:Mass_Change'),
                url: '/seller/catalogproduct/masschange',
                notInAcl: true,
            },
        ],
    },
    {
        aclCode: 'seller_stock_multichannel',
        key: 'stock',
        label: t('menu:Stock'),
        url: '/seller/stockchannel',
        isMultichannel: true,
        notInAcl: true,
    },
];

export default optionsSeller;
