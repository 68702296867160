/* eslint-disable import/prefer-default-export */
export const optionsClient = (t) => [
    {
        aclCode: 'header_client',
        key: 'client',
        label: t('menu:Client'),
        notInAcl: true,
        children: [
            {
                aclCode: 'client',
                key: 'client',
                label: t('menu:Client'),
                url: '/admin/client/client',
                notInAcl: true,
            },
        ],
    },
];
