/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useTranslation } from '@i18n';

import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';

import Sidebar from '@modules/theme/layout/components/sidebar';
import SidebarSeller from '@modules/theme/layout/components/seller/sidebar';
import Header from '@modules/theme/layout/components/header';
import HeaderSeller from '@modules/theme/layout/components/seller/header';

import { helpersMenuList } from '@modules/theme/helpers';
import sellerMenus from '@modules/theme/helpers/seller';
import useStyles from '@modules/theme/layout/style';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { sentry } from '@root/swift.config.js';
import { getAppEnv } from '@helpers/env';
import { storeLogoVar } from '@core/services/graphql/cache';

const appEnv = getAppEnv();
sentry.enabled && sentry.enableMode === appEnv ? Sentry.init({
    dsn: sentry.errorTracing ? process.env.SENTRY_URL : null,
    integrations: sentry.performanceTracing ? [new BrowserTracing()] : [],
    tracesSampleRate: sentry.tracesSampleRate,
}) : null;

const Loading = dynamic(() => import('@common_loaders/Backdrop'), { ssr: false });
const Message = dynamic(() => import('@common_toast'), { ssr: false });

const Layout = (props) => {
    const {
        children, pageConfig, useBreadcrumbs = true, plainMode = false, seller, setSidebarOpen,
        hideHeader = false, hideSidebar = false,
    } = props;
    const { t } = useTranslation('menu');
    const classes = useStyles();
    const router = useRouter();
    const storeLogo = storeLogoVar();

    // eslint-disable-next-line no-unused-vars
    const [menuList, setMenuList] = useState(seller ? sellerMenus(t) : helpersMenuList(t));
    const [open, setOpen] = useState(false);
    const [loadLang, setLoadLang] = useState(false);
    const [refetchBreadcrumb, setRefetchBreadcrumb] = useState(false);
    const [activeParentMenu, setActiveParentMenu] = useState();
    const [activeChildMenu, setActiveChildMenu] = useState();
    const [activeGrandChildMenu, setActiveGrandChildMenu] = useState();
    const [backdropLoader, setBackdropLoader] = useState(false);
    const [currentLocation, setCurrentLocation] = useState('');
    const [toastMessage, setToastMessage] = useState({
        open: false,
        variant: '',
        text: '',
    });
    // console.log({ activeChildMenu }, { activeParentMenu });

    const setOpenSidebar = (e) => {
        if (setSidebarOpen) {
            setSidebarOpen(e);
            setOpen(e);
        } else {
            setOpen(e);
        }
    };

    // const { data: dataStores, loading: loadingStores } = gqlServices.csStores({
    //     skip: !seller,
    // });

    const mappedMenuList = menuList.reduce((accumulator, parent) => {
        const parentBreadcrumb = { url: parent.url || '', label: parent.label };
        const mappedParent = {
            key: parent.key,
            url: parent.url || '',
            breadcrumb: [parentBreadcrumb],
        };
        accumulator.push(mappedParent);
        if (parent?.children?.length) {
            const mappedMenu = [];
            parent.children.forEach((child) => {
                const childBreadcrumb = [parentBreadcrumb, { url: child.url || '', label: child.label }];
                if (child?.children?.length) {
                    child.children.map((grandChild) => {
                        const grandChildBreadcrumb = [childBreadcrumb, { url: grandChild.url || '', label: grandChild.label }];
                        return mappedMenu.push({
                            key: grandChild.key,
                            url: grandChild.url || '',
                            parentKey: child.key,
                            breadcrumb: grandChildBreadcrumb,
                        });
                    });
                }
                return mappedMenu.push({
                    key: child.key,
                    url: child.url || '',
                    parentKey: parent.key,
                    breadcrumb: childBreadcrumb,
                });
            });
            accumulator = [...accumulator, ...mappedMenu];
        }
        return accumulator;
    }, []);

    const getBreadcrumbData = () => {
        const activeMenu = mappedMenuList.find((e) => e.url === router.pathname);
        let activeMenuBreadcrumb = [];
        if (pageConfig?.customBreadcrumb) {
            activeMenuBreadcrumb = pageConfig.customBreadcrumb;
        } else if (activeMenu) {
            activeMenuBreadcrumb = activeMenu && activeMenu.breadcrumb;
        } else {
            const activeMenuSecondary = mappedMenuList.find((e) => e.url === router.pathname?.split('/').slice(0, 3).join('/'));
            activeMenuBreadcrumb = (activeMenuSecondary && activeMenuSecondary.breadcrumb) || [];
            activeMenuBreadcrumb.push({ url: router.asPath, label: pageConfig?.title ? pageConfig.title : currentLocation });
        }
        return [{ url: seller ? '/' : '/admin', label: 'Home' }, ...activeMenuBreadcrumb];
    };

    const handleCloseMessage = () => {
        setToastMessage({ ...toastMessage, open: false });
    };

    // useEffect(() => {
    //     if (dataStores?.csStores?.data && !loadingStores) {
    //         if (seller) {
    //             const temp = menuList;
    //             const catalogProduct = temp.find(({ key }) => key === 'catalogproduct');
    //             const channelProduct = catalogProduct?.children?.find(({ key }) => key === 'channelproduct');

    //             const mappingStores = dataStores?.csStores?.data?.map((store) => JSON.stringify({
    //                 aclCode: `channelproduct_${store.channel?.channel_code}`,
    //                 key: `channelproduct_${store.channel?.channel_code}`,
    //                 label: store.channel?.channel_name,
    //                 url: `/seller/catalogproduct/channelproduct/${store.channel?.channel_code}`,
    //                 notInAcl: true,
    //             }));
    //             const uniqueStores = new Set(mappingStores);
    //             channelProduct.children = Array.from(uniqueStores).map(JSON.parse);
    //             setMenuList(temp);
    //             setRefetchBreadcrumb(true);
    //         }
    //     }
    // }, [dataStores, loadingStores]);

    // useEffect(() => {
    //     const routeNotAuth = ['/login', '/admin/login', '/version'];
    //     if (!routeNotAuth.some((v) => router.pathname?.indexOf(v) >= 0)) {
    //         if (!seller) {
    //             getNotificationList();
    //         }
    //     }
    // }, [router]);

    const removeLastPathOnUrl = (url, sliceCount) => {
        const output = url.split('/').slice(0, sliceCount).join('/');
        return output;
    };

    const showHeader = () => {
        if (typeof pageConfig === 'undefined' || (pageConfig && typeof pageConfig.header === 'undefined')) {
            return true;
        }
        return pageConfig && pageConfig.header;
    };

    const showSidebar = () => {
        if (typeof pageConfig === 'undefined' || (pageConfig && typeof pageConfig.sidebar === 'undefined')) {
            return true;
        }
        return pageConfig && pageConfig.sidebar;
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.backdropLoader = setBackdropLoader;
            window.toastMessage = setToastMessage;
            if (window.innerWidth >= 768) setOpenSidebar(true);
        }
    }, []);

    useEffect(() => {
        const activeMenuFirstChild = mappedMenuList.find((e) => e.url === (router && router.asPath) || e.url === (router && router.pathname));

        if (activeMenuFirstChild && activeMenuFirstChild.parentKey) {
            if (activeMenuFirstChild && activeMenuFirstChild.parentKey) {
                const activeMenuParent = mappedMenuList.find((e) => e.key === activeMenuFirstChild.parentKey);
                if (activeMenuParent?.parentKey) {
                    const activeMenuGrandParent = mappedMenuList.find((e) => e.key === activeMenuParent.parentKey);
                    setActiveGrandChildMenu(activeMenuFirstChild);
                    setActiveChildMenu(activeMenuParent);
                    setActiveParentMenu(activeMenuGrandParent);
                } else {
                    setActiveChildMenu(activeMenuFirstChild);
                    setActiveParentMenu(mappedMenuList.find((e) => e.key === activeMenuFirstChild.parentKey));
                }
            } else {
                setActiveParentMenu(activeMenuFirstChild);
            }
        } else {
            let activeMenuSecondChild = null;

            for (let i = 0; i < mappedMenuList.length; i += 1) {
                let count = 3;
                while (count <= 5) {
                    if (count <= 5 && (mappedMenuList[i].url === removeLastPathOnUrl(router && router.asPath, count)
                        || mappedMenuList[i].url === removeLastPathOnUrl(router && router.pathname, count))) {
                        activeMenuSecondChild = mappedMenuList[i];
                        break;
                    }
                    count += 1;
                }
            }

            if (activeMenuSecondChild && activeMenuSecondChild.parentKey) {
                const activeMenuParent = mappedMenuList.find((e) => e.key === activeMenuSecondChild.parentKey);
                const activeMenuGrandParent = mappedMenuList.find((e) => e.key === activeMenuParent.parentKey);
                if (activeMenuGrandParent) {
                    setActiveGrandChildMenu(activeMenuSecondChild);
                    setActiveChildMenu(activeMenuParent);
                    setActiveParentMenu(activeMenuGrandParent);
                } else {
                    setActiveChildMenu(activeMenuSecondChild);
                    setActiveParentMenu(activeMenuParent);
                }
            } else {
                setActiveParentMenu(activeMenuSecondChild);
            }
        }
        setRefetchBreadcrumb(false);
    }, [router, menuList, refetchBreadcrumb]);

    useEffect(() => {
        setCurrentLocation((old) => {
            if (activeChildMenu?.breadcrumb?.filter((val) => val?.url)?.[0]?.label) {
                const labelMenu = activeChildMenu?.breadcrumb?.filter((val) => val?.url)?.[0]?.label;
                if (router.pathname.split('/').length > 4) {
                    const lengthPath = router.pathname.split('/').length;

                    if (!router.pathname.split('/')[lengthPath - 1].includes('[')) {
                        const pathRoute = router.pathname.split('/')[lengthPath - 1];
                        return `${pathRoute?.charAt(0)?.toUpperCase() + pathRoute.slice(1)} ${labelMenu}`;
                    }
                    const pathRoute = router.pathname.split('/')[lengthPath - 2];
                    return `${pathRoute?.charAt(0)?.toUpperCase() + pathRoute.slice(1)} ${labelMenu}`;
                }
                return labelMenu;
            }

            if (activeParentMenu?.breadcrumb?.[0]?.label) {
                return activeParentMenu?.breadcrumb?.[0]?.label;
            }

            if (router.pathname.split('/')?.[1] === 'login') {
                return 'Login';
            }

            return old;
        });
    }, [activeGrandChildMenu, activeChildMenu, activeParentMenu, router]);

    return (
        <>
            <Head>
                <title>{pageConfig?.title ? pageConfig.title : currentLocation}</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            </Head>
            {loadLang ? (
                <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progress} size={80} />
                </div>
            )
                : (
                    <>
                        <div className={classes.root}>
                            {plainMode || hideHeader ? null : showHeader() && (
                                seller ? (
                                    <HeaderSeller
                                        mappedMenuList={sellerMenus}
                                        open={open}
                                        setOpen={setOpenSidebar}
                                        storeLogo={storeLogo}
                                        setLoadLang={setLoadLang}
                                        hideSidebar={hideSidebar}
                                    />
                                )
                                    : (
                                        <Header
                                            mappedMenuList={mappedMenuList}
                                            breadcrumbData={getBreadcrumbData()}
                                            open={open}
                                            setOpen={setOpenSidebar}
                                            storeLogo={storeLogo}
                                            setLoadLang={setLoadLang}
                                            hideSidebar={hideSidebar}
                                        />
                                    )
                            )}
                            {plainMode || hideSidebar ? null : showSidebar() && (
                                <>
                                    {seller ? (
                                        <SidebarSeller
                                            activeParentMenu={activeParentMenu}
                                            setActiveParentMenu={setActiveParentMenu}
                                            activeChildMenu={activeChildMenu}
                                            setActiveChildMenu={setActiveChildMenu}
                                            activeGrandChildMenu={activeGrandChildMenu}
                                            setActiveGrandChildMenu={setActiveGrandChildMenu}
                                            open={open}
                                            setOpen={setOpenSidebar}
                                            menuList={menuList}
                                            storeLogo={storeLogo}
                                        />
                                    )
                                        : (
                                            <Sidebar
                                                activeParentMenu={activeParentMenu}
                                                setActiveParentMenu={setActiveParentMenu}
                                                activeChildMenu={activeChildMenu}
                                                setActiveChildMenu={setActiveChildMenu}
                                                activeGrandChildMenu={activeGrandChildMenu}
                                                setActiveGrandChildMenu={setActiveGrandChildMenu}
                                                open={open}
                                                setOpen={setOpenSidebar}
                                                menuList={menuList}
                                                storeLogo={storeLogo}
                                            />
                                        )}
                                </>
                            )}
                            <main
                                className={showHeader() ? classes.content : classes.contentNoHeader}
                                style={{ width: `calc(100% - ${open ? 266 : 70}px)` }}
                            >
                                <Loading open={backdropLoader} />
                                <Message open={toastMessage.open} variant={toastMessage.variant} setOpen={handleCloseMessage} message={toastMessage.text} />
                                {/* necessary for content to be below app bar */}
                                <div className={showHeader() && !plainMode ? classes.toolbar : ''} />
                                {showHeader() && useBreadcrumbs && !plainMode && (
                                    <Hidden smUp implementation="css">
                                        {/* <Breadcrumb data={getBreadcrumbData()} /> */}
                                        <div style={{ height: 25 }} />
                                    </Hidden>
                                )}
                                {children}
                            </main>
                        </div>
                    </>
                )}
        </>
    );
};

export default Layout;
