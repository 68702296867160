/* eslint-disable import/prefer-default-export */
export const optionsAutomation = (t) => [
    {
        aclCode: 'header_automation',
        key: 'automation',
        label: t('menu:Automation'),
        notInAcl: true,
        children: [
            {
                aclCode: 'integration_automation',
                key: 'automationlist',
                label: t('menu:Automation_List'),
                url: '/admin/automation/automationlist',
                notInAcl: true,
            },
            {
                aclCode: 'integration_automation',
                key: 'automationlog',
                label: t('menu:Automation_Log'),
                url: '/admin/automation/automationlog',
                notInAcl: true,
            },
        ],
    },
];
