/* eslint-disable import/prefer-default-export */
export const optionsStore = (t) => [
    {
        aclCode: 'header_store',
        key: 'store',
        label: t('menu:Store'),
        notInAcl: true,
        children: [
            {
                aclCode: 'productconfiguration',
                key: 'productconfiguration',
                label: t('menu:Product_Configuration'),
                url: '/admin/store/productconfiguration',
                notInAcl: true,
            },
        ],
    },
];
