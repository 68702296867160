/* eslint-disable import/prefer-default-export */
export const optionsApiLog = (t) => [
    {
        aclCode: 'header_api',
        key: 'log',
        label: t('menu:Logs'),
        notInAcl: true,
        children: [
            {
                aclCode: 'logs',
                key: 'log',
                label: t('menu:API Log'),
                url: '/admin/logs/apilog',
                notInAcl: true,
            },
        ],
    },
];
