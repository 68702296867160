/* eslint-disable import/prefer-default-export */
export const optionsCategory = (t) => [
    {
        aclCode: 'header_catalog',
        key: 'catalog',
        label: t('menu:Catalog'),
        notInAcl: true,
        children: [
            {
                aclCode: 'category_mapping',
                key: 'categorymapping',
                label: t('menu:Category_Mapping'),
                url: '/admin/catalog/categorymapping',
                notInAcl: true,
            },
            {
                aclCode: 'channel_category',
                key: 'channelcategory',
                label: t('menu:Channel_Category'),
                url: '/admin/catalog/channelcategory',
                notInAcl: true,
            },
            {
                aclCode: 'channel_attribute_mapping',
                key: 'channelattributemapping',
                label: t('menu:Channel_Attribute_Mapping'),
                url: '/admin/catalog/channelattributemapping',
                notInAcl: true,
            },
            {
                aclCode: 'channel_attribute_config',
                key: 'channelattributeconfig',
                label: t('menu:Channel_Attribute_Config'),
                url: '/admin/catalog/channelattributeconfig',
                notInAcl: true,
            },
        ],
    },
];
